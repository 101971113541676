<template>
  <tableau view="1/sheet0_1"/>
</template>

<script>
import Tableau from "./Tableau"

export default {
  components: { Tableau }
}
</script>
